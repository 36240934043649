<template>
  <div class="section-wrapper">
    <div class="sub-section-wrapper">
      <breadcrumbs :content="breadcrumbsContents"></breadcrumbs>
      <div class="radio-wrapper">
        <span class="option-td" v-for="(item, index) in options" :key="index"
          @click="linkTo(nameExtra.url+'/' + item.value)" :style="
            choosed === item.value
              ? { background: '#00508e', color: '#fff' }
              : {}
          ">{{ item.text }}</span>
      </div>
      <div class="wrapper-box">
        <div class="ele-item" v-for="(ele, index) in contentItems" :key="index">
          <div class="ele-inner-wrapper">
            <div class="ele-a">
              <div class="a-des">
                <div class="a-des-title">
                  {{ ele.name }}
                </div>
                <div class="a-des-content">
                  <p>{{ ele.description }}</p>
                </div>
              </div>
            </div>
            <div class="ele-b">
              <p class="time">{{ ele.pubdate }}</p>
            </div>
            <div class="ele-c">
              <div class="b-link" @click="view(ele.url[0])">
                <p>
                  <span>{{viewText(ele.url[0])}}</span>
                  <span class="icon-dl">
                    <Icon type="ios-download-outline" :size="24" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-wrapper">
        <Page :total="totalItems" :page-size="Tpage.currentSize" @on-change="handlePage" class-name="page-d" />
      </div>
      <Spin v-if="spin" fix size="large"></Spin>
    </div>
  </div>
</template>
<script>
import breadcrumbs from "@/components/Libs/Breadcrumbs.vue";
import breadcrumbsText from "@/libs/mixins/BreadcrumbsText.js";

export default {
  name: "fileList",
  components: {
    breadcrumbs,
  },
  props: {
    choosed: {
      type: String,
      required: true,
      default: "all",
    },
  },
  mixins: [breadcrumbsText],
  data() {
    return {
      nameExtra: {
        url: "/file_list",
        text: "文件中心",
      },
      options: [
        { text: "全部", value: "all" },
        // { text: "公开性文件", value: "public" },
        // { text: "测试申请表", value: "test_application_form" },
        // { text: "服务指南", value: "service_guide" },
        // { text: "资质证书", value: "certificate" },
        // { text: "公司宣传册", value: "brochure" },
        // { text: "其他", value: "other" },
      ],
      contentItems: [
        {
          fileUrl: process.env.BASE_URL + "img/index/risk.jpg",
          name: "检测试验",
          pubdate: "2021-05-06",
          description:
            "轨道交通领域环境检测、振动噪声、电磁兼容、防火试验、电气安全检测；机车车辆型式试验、系统互操作性测试；材料及部件的力学性能、物理金相、化学分析、无损检测、失效分析。轨道交通领域环境检测、振动噪声、电磁兼容、防火试验、电气安全检测；机车车辆型式试验、系统互操作性测试；材料及部件的力学性能、物理金相、化学分析、无损检测、失效分析。",
        },
        {
          fileUrl: process.env.BASE_URL + "img/index/risk.jpg",
          name: "检验评估",
          pubdate: "2021-05-06",
          description: "",
        },
        {
          fileUrl: process.env.BASE_URL + "img/index/risk.jpg",
          name: "认证服务",
          pubdate: "2021-05-06",
          description:
            "陆地交通设备领域自愿性产品认证（含安全认证）、城轨装备认证、服务认证、质量体系认证；轨道交通领域出口产品认证。",
        },
      ],
      spin: false,
      Tpage: {
        currentPage: 1,
        currentSize: 12
      },
      totalItems: 0,
      code: "archive",
      lang: "中文",
      keyword: "",
      pdfUrl: "/pdf/web/viewer.html?file=",
      checkUrl: "",
    };
  },
  created() {
    this.getData();
    this.getOption();
  },
  methods: {
    getOption() {
      this.$axios
        .post(this.$url + "/option", {
          askData: this.code,
          lang: this.lang,
        })
        .then((response) => {
          for (let i of response.data.res_record.category) {
            this.options.push(i);
          }
        });
    },
    handlePage(value) {
      this.Tpage.currentPage = value;
      this.getData();
    },
    getData() {
      this.spin = true;
      this.$axios
        .post(this.$url + '/flist', {
          page: this.Tpage,
          askData: this.code,
          keyword: this.keyword,
          choosed: this.choosed,
          lang: this.lang,
        })
        .then(
          response => {
            this.contentItems = response.data.res_record;
            this.totalItems = response.data.total_n;
            this.spin = false;
          }
        );
    },
    view(url) {
      // alert("暂未开放下载！");
      if (url.endsWith('pdf')) {
        this.checkUrl = encodeURIComponent(
          this.$fileUrlFacWithoutRight(url)
        );
        window.open(this.pdfUrl + this.checkUrl);
      } else {
        window.open(this.$fileUrlFacWithoutRight(url));
      }
    },
    viewText(url){
      if (url&&url.endsWith('pdf')) {
        return "查看";
      } else {
        return "下载";
      }
    },
    linkTo: function (value) {
      this.spin = true;
      location.href = value;
    },
  },
  computed: {
  },
  mounted() { },
};
</script>
<style scoped>
.section-wrapper {
  width: 100%;
}

.sub-section-wrapper {
  width: 1280px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.wrapper-box {
  padding: 50px 0;
}

.ele-item {
  width: 100%;
  padding: 0 15px;
  border-bottom: 1px solid #ddd;
}

.ele-item:first-child {
  border-top: 1px solid #ccc;
}

.ele-item:hover {
  background: #fbfbfc;
}

.ele-inner-wrapper {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: space-between;
}

.ele-a {
  overflow: hidden;
}

@keyframes it {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.ele-a img:hover {
  animation: it 10s ease 0s forwards;
}

.ele-a {
  text-align: left;
  width: 60%;
}

.a-des {
  padding: 0 10px;
  height: 110px;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  font-size: 16px;
  color: #666;
  overflow: hidden;
}

.a-des-title {
  color: #333;
  font-family: "Helvetica Neue", Helvetica, Arial, Tahoma, "Microsoft YaHei",
    "PingFang SC", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei",
    sans-serif;
  outline: none;
  font-size: 18px;
  text-transform: uppercase;
  text-align: left;
  width: 100%;
  margin: 5px 0;
}

.a-des-content {
  width: 100%;
  text-align: left;
  color: rgb(165, 165, 165);
  font-size: 14px;
  line-height: 160%;
  font-family: "微软雅黑";
  max-height: 42px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.ele-b {
  width: 20%;
  justify-content: right;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  font-size: 16px;
  color: #666;
  overflow: hidden;
}

.ele-c {
  width: 10%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  font-size: 16px;
  color: #666;
  overflow: hidden;
}

.b-link {
  font-size: 16px;
  border: 1px solid #00508e;
  padding: 10px 30px;
  cursor: pointer;
}

.b-link:hover {
  background: #00508e;
  color: #fff;
  font-weight: 600;
}

.icon-dl {
  position: relative;
  top: 2px;
  left: 3px;
}

.page-wrapper {
  text-align: center;
  padding: 50px 0;
}

.radio-wrapper {
  position: absolute;
  right: 0;
  top: 50px;
}

.option-td {
  display: inline-block;
  text-align: center;
  font-size: 15px;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-right: none;
}

.option-td:last-child {
  border-right: 1px solid #ddd;
}

.option-td:hover {
  outline: 1px solid #00508e;
  outline-offset: -1px;
}
</style>